.auth-form {
  width: 100%;

  button {
    --ant-color-bg-container-disabled: #1677ffa6;
    --ant-color-text-disabled: var(--white);
  }
}

:root {
  --ant-modal-header-padding: 100px;
}