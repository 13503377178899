.list {
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
  padding-bottom: 16px;
  margin-bottom: auto;
}
  
.list * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}