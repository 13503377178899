.server-error {
  color: var(--server-error-color);
  background: var(--server-error-bg);
  text-align: center;
  --ant-padding-lg: 12px;
  
  &-title,
  &-message {
    color: var(--server-error-color);
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
  }

  &-message {
    font-size: 14px;
  }
}