.color-success {
  color: var(--color-success);
}

.color-warn {
  color: var(--color-warn);
}

.color-error {
  color: var(--color-error);
}

.text-light-grey {
  color: var(--light-grey);
}