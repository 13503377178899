@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

#root .button {
  &--success {
    background: var(--btn-success-bg);

    &:hover {
      background: var(--btn-success-hover-bg);
    }
  }

  &--error {
    background: var(--btn-success-bg);
  }

  &--animated {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
}