:root {
  --theme-primary: #282C34;
  --theme-secondary: #B6B6B6;

  --xbo-color: #6319ff;

  --background-root: #DDDDDD;

  --white: #FFFFFF;
  --green: #66bb6a;
  --orange: #ff9800;
  --red: #f44336;
  --light-grey: rgba(0, 0, 0, 0.45);

  --btn-primary-bg: #1677ff;
  --btn-primary-color: var(--white);

  --btn-success-bg: #66bb6a;
  --btn-success-hover-bg: #4caf50;
  --btn-error-bg: #f44336;

  --color-success: var(--green);
  --color-warn: var(--orange);
  --color-error: var(--red);

  --fontRegular12: 100 12px/12px 'Pier', arial, sans-serif;
  --fontRegular14: 100 14px/14px 'Pier', arial, sans-serif;
  --fontRegular16: 100 16px/16px 'Pier', arial, sans-serif;
  --fontRegular18: 100 18px/18px 'Pier', arial, sans-serif;
  --fontRegular20: 100 20px/20px 'Pier', arial, sans-serif;
  --fontRegular22: 100 22px/22px 'Pier', arial, sans-serif;

  --ant-form-item-margin-bottom: 4rem;

  --app-height: 100vh;

  --field-border-color-invalid: #f44336;
  --field-color-invalid: #f44336;

  --server-error-bg: #FFCDD2;
  --server-error-color: #7c221b;

  --header-height: 50px;

  --ant-color-border: red !important;
}