.field {
  position: relative;
}

.field-tag {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
  margin: 0;
}

// .field {

//   background: #ffffff;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #d9d9d9;
//   height: 42px;

//   box-sizing: border-box;
//   margin: 0;
//   padding: 4px 11px;
//   color: rgba(0, 0, 0, 0.88);
//   font-size: 14px;
//   line-height: 1.5714285714285714;
//   list-style: none;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//   position: relative;
//   display: inline-block;
//   width: 100%;
//   min-width: 0;
//   border-radius: 6px;
//   transition: all 0.2s;

//   &--invalid,
//   &--invalid:focus,
//   &--invalid:hover {
//     color: var(--field-border-color-invalid);
//     border-color: var(--field-border-color-invalid);
//   }
// }