.summary {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  height: 100%;
  padding-bottom: 16px;
}

.summary-table {
  padding-bottom: 16px;
  overflow: scroll;
  flex: 1 1;
  // height: 100%;
}
  
// .ant-table-body {
//   min-height: var(--container-height);
// }