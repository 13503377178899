#root {
  background: var(--background-root);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0 16px 16px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 72px;
  padding: 16px 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  height: calc(100vh - 72px);
  overflow: hidden;
  padding: 0 0 16px;
  box-sizing: border-box;
  // padding: 16px;
  // margin-top: 56px;
}